import React, { useState } from "react";

export default function ListAirportsForDepartmentAndCity({
  airportsForDepartmentAndCity,
}) {
  const [searchTerm, setSearchTerm] = useState("");

  const filteredAirports = airportsForDepartmentAndCity
    .map((item) => ({
      ...item,
      aeropuertos: item.aeropuertos.filter(
        (airport) =>
          airport.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          airport.city?.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.departamento.toLowerCase().includes(searchTerm.toLowerCase())
      ),
    }))
    .filter((item) => item.aeropuertos.length > 0);

  return (
    <div className="airports-container">
      <input
        type="text"
        placeholder="Buscar aeropuertos por nombre, ciudad o departamento..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="search-input"
      />
      {filteredAirports.length > 0 ? (
        filteredAirports.map((item, index) => (
          <div key={index} className="department-section">
            <h2 className="department-title">{item.departamento}</h2>
            <p className="airport-count">
              Número de aeropuertos: {item.numeroDeAeropuertos}
            </p>
            {item.aeropuertos.map((airport) => (
              <div key={airport.id} className="airport-card">
                <h3 className="city-name">
                  {airport.city?.name || "Ciudad Desconocida"}
                </h3>
                <p className="airport-name">
                  <strong>{airport.name}</strong> ({airport.type})
                </p>
                <p className="airport-codes">
                  IATA: {airport.iataCode || "N/A"}, OACI:{" "}
                  {airport.oaciCode || "N/A"}
                </p>
                <p className="airport-coordinates">
                  Coordenadas: {airport.latitude}, {airport.longitude}
                </p>
              </div>
            ))}
          </div>
        ))
      ) : (
        <p className="no-results">
          No se encontraron aeropuertos con el término de búsqueda.
        </p>
      )}
    </div>
  );
}
