import React, { useEffect, useState } from "react";
import { getAirportsGroupedByRegionDepartmentCityAndType } from "../../services/dataProcessing";
import ExecutionTime from "../ExecutionTime";
import TableAirportsGroupedByRegionDepartmentCityAndType from "./TableAirportsGroupedByRegionDepartmentCityAndType";
import TotalEntity from "../TotalEntity";

export default function AirportsGroupedByRegionDepartmentCityAndType() {
  const [
    airportsGroupedByRegionDepartmentCityAndType,
    setAirportsGroupedByRegionDepartmentCityAndType,
  ] = useState({});
  const [time, setTime] = useState(0);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    getAirportsGroupedByRegionDepartmentCityAndType().then(({ data, time }) => {
      setAirportsGroupedByRegionDepartmentCityAndType(data);
      calculatorTotal(data);
      setTime(time);
    });
  }, []);

  function calculatorTotal(data) {
    let total = 0;

    Object.values(data).forEach((region) => {
      Object.values(region).forEach((department) => {
        Object.values(department).forEach((city) => {
          Object.values(city).forEach((count) => {
            total += count; // Suma los valores de los conteos de aeropuertos
          });
        });
      });
    });

    setTotal(total);
  }

  return (
    <>
      <h2 className="title">
        Aeropuertos agrupados por región, departamento, ciudad y tipo
      </h2>
      <br />
      <ExecutionTime time={time} />
      <TotalEntity total={total} />
      <br />
      <TableAirportsGroupedByRegionDepartmentCityAndType
        airportsGroupedByRegionDepartmentCityAndType={
          airportsGroupedByRegionDepartmentCityAndType
        }
      />
    </>
  );
}
