import React from "react";
import { useState, useEffect } from "react";
import "./touristicAttractions.css";
import { getTouristicAttractionForCityAndDepartment } from "../../services/dataProcessing";
import TotalEntity from "../TotalEntity";
import ExecutionTime from "../ExecutionTime";
import ListTouristicAttractions from "./ListTouristicAttractions";

export default function TouristicAttractions() {
  const [
    touristicAttractionsForCityAndDeparment,
    settouristicAttractionsForCityAndDeparment,
  ] = useState([]);
  const [time, setTime] = useState(0);
  const [total, setTotal] = useState(0);

  const calculatorTotal = (data) => {
    const total = data.reduce(
      (acc, attractionsForCity) => acc + attractionsForCity.numeroDeAtracciones,
      0
    );
    setTotal(total);
  };

  useEffect(() => {
    getTouristicAttractionForCityAndDepartment().then(({ data, time }) => {
      settouristicAttractionsForCityAndDeparment(data);
      calculatorTotal(data);
      setTime(time);
    });
  }, []);

  return (
    <>
      <h2 className="title">
        Atracciones Turísticas por Departamento y Ciudad
      </h2>
      <TotalEntity total={total} />
      <ExecutionTime time={time} />
      <ListTouristicAttractions
        touristicAttractionsForCityAndDeparment={
          touristicAttractionsForCityAndDeparment
        }
      />
    </>
  );
}
