import React, { useEffect, useState } from "react";
import "./tableAirportsGroupedByRegionDepartmentCityAndType.css";

export default function TableAirportsGroupedByRegionDepartmentCityAndType({
  airportsGroupedByRegionDepartmentCityAndType,
}) {
  const [regions, setRegions] = useState([]);
  useEffect(() => {
    fetch("https://api-colombia.com/api/v1/Region")
      .then((response) => response.json())
      .then((data) => setRegions(data));
  }, []);

  const getRegionName = (regionId) => {
    const region = regions.find((r) => r.id === parseInt(regionId, 10));
    return region ? region.name : "Desconocido";
  };
  return (
    <table>
      <thead>
        <tr>
          <th>Región</th>
          <th>Departamento</th>
          <th>Ciudad</th>
          <th>Tipo</th>
          <th>Conteo</th>
        </tr>
      </thead>
      <tbody>
        {Object.keys(airportsGroupedByRegionDepartmentCityAndType).map(
          (regionId) =>
            Object.keys(
              airportsGroupedByRegionDepartmentCityAndType[regionId]
            ).map((department) =>
              Object.keys(
                airportsGroupedByRegionDepartmentCityAndType[regionId][
                  department
                ]
              ).map((city) =>
                Object.keys(
                  airportsGroupedByRegionDepartmentCityAndType[regionId][
                    department
                  ][city]
                ).map((type) => (
                  <tr key={`${regionId}-${department}-${city}-${type}`}>
                    <td>{getRegionName(regionId)}</td>
                    <td>{department}</td>
                    <td>{city}</td>
                    <td>{type}</td>
                    <td>
                      {
                        airportsGroupedByRegionDepartmentCityAndType[regionId][
                          department
                        ][city][type]
                      }
                    </td>
                  </tr>
                ))
              )
            )
        )}
      </tbody>
    </table>
  );
}
