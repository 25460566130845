import { getAirports } from "./airport";
import { getPresidents } from "./president";
import { getTouristicAttractions } from "./touristic-attraction";
// a. Realizar agrupamiento sobre la entidad Presidentes según el partido político.
// Devolver una estructura de datos (array de objetos u objeto de objetos), con la lista
// de partidos políticos y que esté ordenada descendentemente por el conteo de
// presidentes electos.

function getPresidentsForPoliticalParty() {
  const startTime = new Date().getTime();
  return getPresidents().then((presidents) => {
    const endTime = new Date().getTime();
    const politicalParties = [];
    const groupPresidentsForPoliticalParties = presidents.reduce(
      (acc, president) => {
        const key = president.politicalParty;
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(president);
        return acc;
      },
      politicalParties
    );

    const sortedPoliticalParties = Object.entries(
      groupPresidentsForPoliticalParties
    )
      .map(([party, presidents]) => ({
        party,
        presidents,
        count: presidents.length,
      }))
      .sort((a, b) => b.count - a.count);

    return { data: sortedPoliticalParties, time: endTime - startTime };
  });
}

// b. Realizar agrupamiento sobre la entidad Atracciones Turísticas por departamento y
// ciudad. Devolver una estructura de datos (array de objetos u objeto de objetos), con
// los valores de los campos correspondientes y el conteo de las atracciones turísticas.

function getTouristicAttractionForCityAndDepartment() {
  const startTime = new Date().getTime();
  return getTouristicAttractions().then((touristicAttractions) => {
    const endTime = new Date().getTime();
    const touristicAttractionGroup = touristicAttractions.reduce(
      (touristicAttractionsReduce, touristicAttraction) => {
        const keyDepartment = touristicAttraction.city.departmentId;
        const keyCity = touristicAttraction.city.name;
        if (!touristicAttractionsReduce[keyDepartment]) {
          touristicAttractionsReduce[keyDepartment] = [];
        }
        if (!touristicAttractionsReduce[keyDepartment][keyCity]) {
          touristicAttractionsReduce[keyDepartment][keyCity] = [];
        }
        touristicAttractionsReduce[keyDepartment][keyCity].push(
          touristicAttraction
        );
        return touristicAttractionsReduce;
      },
      {}
    );
    return {data: Object.entries(touristicAttractionGroup).map(([a, b]) => ({
      departamentoId: a,
      atraccionesPorCiudad: b,
      numeroDeAtracciones: Object.values(b).reduce(
        (acc, city) => acc + city.length,
        0
      ),
      atracciones: Object.values(b).reduce((acc, city) => acc.concat(city), []),
    })), time: endTime - startTime};
  });
}

// c. Realizar agrupamiento sobre la entidad Aeropuertos por departamento y ciudad.
// Devolver una estructura de datos (array de objetos u objeto de objetos), con los
// valores de los campos correspondientes y el conteo de los aeropuertos.

function getAirportForDepartmentAndCity() {
  const startTime = new Date().getTime();
  return getAirports().then((airports) => {
    const endTime = new Date().getTime();
    const airportGroup = airports.reduce((acc, airport) => {
      const keyDepartment = airport.department.name;
      const keyCity = airport.city.name;
      if (!acc[keyDepartment]) {
        acc[keyDepartment] = [];
      }
      if (!acc[keyDepartment][keyCity]) {
        acc[keyDepartment][keyCity] = [];
      }
      acc[keyDepartment][keyCity].push(airport);
      return acc;
    }, {});

    return {data: Object.entries(airportGroup).map(([a, b]) => {
      const ciudades = Object.values(b);
      return {
        aeropuertosPorCiudades: b,
        aeropuertos: Object.values(b).reduce(
          (acc, city) => acc.concat(city),
          []
        ),
        departamento: a,
        numeroDeAeropuertos: ciudades.reduce((acc, city) => {
          return acc + city.length;
        }, 0),
      };
    }) , time: endTime - startTime}
  });
}

// d. Realizar agrupamiento de la entidad Aeropuertos por Región, Departamento,
// Ciudad y Tipo. Devolver una estructura de datos con los valores de los campos
// correspondientes y el conteo de los aeropuertos. A continuación, se expone una
// visualización de dicho agrupamiento.

function getAirportsGroupedByRegionDepartmentCityAndType() {
  const startTime = new Date().getTime();
  return getAirports().then((airports) => {
    const endTime = new Date().getTime();
    const airportsGroup = airports.reduce((acc, airport) => {
      const keyRegion = airport.department.regionId; // Nombre de la región
      const keyDepartment = airport.department.name; // Nombre del departamento
      const keyCity = airport.city.name; // Nombre de la ciudad
      const keyType = airport.type; // Tipo de aeropuerto

      // Asegurarse de que la región exista en el agrupamiento
      if (!acc[keyRegion]) {
        acc[keyRegion] = {};
      }

      // Asegurarse de que el departamento exista dentro de la región
      if (!acc[keyRegion][keyDepartment]) {
        acc[keyRegion][keyDepartment] = {};
      }

      // Asegurarse de que la ciudad exista dentro del departamento
      if (!acc[keyRegion][keyDepartment][keyCity]) {
        acc[keyRegion][keyDepartment][keyCity] = {};
      }

      // Asegurarse de que el tipo exista dentro de la ciudad y aumentar el conteo
      if (!acc[keyRegion][keyDepartment][keyCity][keyType]) {
        acc[keyRegion][keyDepartment][keyCity][keyType] = 0;
      }
      acc[keyRegion][keyDepartment][keyCity][keyType] += 1;

      return acc;
    }, {});

    return {data: airportsGroup, time: endTime - startTime};
  });
}

export {
  getPresidentsForPoliticalParty,
  getTouristicAttractionForCityAndDepartment,
  getAirportForDepartmentAndCity,
  getAirportsGroupedByRegionDepartmentCityAndType,
};
