import React, { useState } from "react";

export default function ListPoliticalParties({ presidentsForPoliticalParty }) {
  const [visibleParties, setVisibleParties] = useState({});
  const [expandedDescriptions, setExpandedDescriptions] = useState({});

  const toggleVisibility = (party) => {
    setVisibleParties((prevVisibleParties) => ({
      ...prevVisibleParties,
      [party]: !prevVisibleParties[party],
    }));
  };

  const toggleDescription = (presidentId) => {
    setExpandedDescriptions((prevExpandedDescriptions) => ({
      ...prevExpandedDescriptions,
      [presidentId]: !prevExpandedDescriptions[presidentId],
    }));
  };

  return (
    <div className="political-parties-container">
      <ul className="political-parties-list">
        {presidentsForPoliticalParty.map((politicalParty) => (
          <li key={politicalParty.party} className="political-party-item">
            <div className="political-party-header">
              <span className="political-party-name">
                {politicalParty.party}
              </span>
              <button
                className="button toggle-button"
                onClick={() => toggleVisibility(politicalParty.party)}
              >
                {visibleParties[politicalParty.party]
                  ? "Ocultar presidentes"
                  : "Ver presidentes"}
              </button>
            </div>
            {visibleParties[politicalParty.party] && (
              <ul className="presidents-list">
                {politicalParty.presidents.map((president) => (
                  <li key={president.id} className="president-item">
                    <div className="president-info">
                      {president.image !== "null" && (
                        <img
                          src={president.image}
                          alt={president.name}
                          className="president-image"
                        />
                      )}
                      <div className="president-details">
                        <p className="president-name">
                          {president.name} {president.lastName} (
                          {president.startPeriodDate} -{" "}
                          {president.endPeriodDate})
                        </p>
                        <p
                          className={
                            expandedDescriptions[president.id]
                              ? "president-description full"
                              : "president-description"
                          }
                        >
                          {president.description}
                        </p>
                        <button
                          className="button toggle-description-button"
                          onClick={() => toggleDescription(president.id)}
                        >
                          {expandedDescriptions[president.id]
                            ? "Leer menos"
                            : "Leer más"}
                        </button>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
}
