import { useState } from "react";
import s from "./config/settings";

import Presidents from "./components/Presidents/Presidents";
import TouristicAttractions from "./components/TouristicAttractions/TouristicAttractions";
import AirportsForDepartmentAndCity from "./components/Airports/AirportsForDepartmentAndCity";
import AirportsGroupedByRegionDepartmentCityAndType from "./components/Airports/AirportsGroupedByRegionDepartmentCityAndType";
import "./app.css";

const tabs = [
  {
    name: "Presidentes",
    component: <Presidents />,
  },
  {
    name: "Atracciones turísticas",
    component: <TouristicAttractions />,
  },
  {
    name: "AeropuertosDC",
    component: <AirportsForDepartmentAndCity />,
  },
  {
    name: "AeropuertosRDCT",
    component: <AirportsGroupedByRegionDepartmentCityAndType />,
  },
];

function App() {
  const [activeTab, setActiveTab] = useState(tabs[3].name);

  const changeTab = (tabName) => {
    setActiveTab(tabName);
  };

  return (
    <div>
      <header>
        <h1>
          {s.TITLE} - {s.AUTOR} - <a href={`mailto:${s.EMAIL}`}>{s.EMAIL}</a> -{" "}
          <a href={s.LINKEDIN} target="_blanck">Linkedin</a>
        </h1>
      </header>
      <main>
        <div className="tab">
          <h2 className='tab-title'>Colombia</h2>
          <nav className="tab-header">
            <ul>
              {tabs.map((tab) => (
                <li
                  key={tab.name}
                  className={activeTab === tab.name ? "active" : ""}
                  onClick={() => changeTab(tab.name)}
                >
                  {tab.name}
                </li>
              ))}
            </ul>
          </nav>
          <div className="tab-content">
            {tabs.map((tab) => (
              <section
                key={tab.name}
                className={activeTab === tab.name ? "active" : ""}
              >
                {tab.component}
              </section>
            ))}
          </div>
        </div>
      </main>
    </div>
  );
}

export default App;
