import React from "react";
import { useEffect, useState } from "react";
import "./airportsForDepartmentAndCity.css";
import { getAirportForDepartmentAndCity } from "../../services/dataProcessing";
import TotalEntity from "../TotalEntity";
import ExecutionTime from "../ExecutionTime";
import ListAirportsForDepartmentAndCity from "./ListAirportsForDepartmentAndCity";

export default function AirportsForDepartmentAndCity() {
  const [time, setTime] = useState(0);
  const [total, setTotal] = useState(0);
  const [airportsForDepartmentAndCity, setAirportsForDepartmentAndCity] =
    useState([]);

  useEffect(() => {
    getAirportForDepartmentAndCity().then(({ data, time }) => {
      setAirportsForDepartmentAndCity(data);
      calculatorTotal(data);
      setTime(time);
    });
  }, []);

  const calculatorTotal = (airportsForDepartmentAndCity) => {
    const total = airportsForDepartmentAndCity.reduce(
      (acc, airport) => acc + airport.numeroDeAeropuertos,
      0
    );
    setTotal(total);
  };

  return (
    <>
      <h2 className="title">Aeropuertos por departamento y ciudad</h2>
      <br />
      <TotalEntity total={total} />
      <ExecutionTime time={time} />
      <br />
      <ListAirportsForDepartmentAndCity
        airportsForDepartmentAndCity={airportsForDepartmentAndCity}
      />
    </>
  );
}
