import React, { useState, useEffect } from 'react';

export default function ListTouristicAttractions({ touristicAttractionsForCityAndDeparment }) {
  const [departments, setDepartments] = useState({});

  useEffect(() => {
    fetch('https://api-colombia.com/api/v1/Department')
      .then(response => response.json())
      .then(data => {
        const deptMap = data.reduce((acc, dept) => {
          acc[dept.id] = dept.name;
          return acc;
        }, {});
        setDepartments(deptMap);
      });
  }, []);

  const groupedData = touristicAttractionsForCityAndDeparment.map(dept => {
    // Agrupar por ciudad dentro de cada departamento
    const cities = dept.atracciones.reduce((acc, attraction) => {
      const cityId = attraction.city.id;
      if (!acc[cityId]) {
        acc[cityId] = {
          cityName: attraction.city.name,
          attractions: []
        };
      }
      acc[cityId].attractions.push(attraction);
      return acc;
    }, {});

    return {
      ...dept,
      cities: Object.values(cities)
    };
  });

  return (
    <div className="attractions-container">
      {groupedData.map((dept) => (
        <div key={dept.departamentoId} className="department-section">
          <h3 className="department-title">Departamento: {departments[dept.departamentoId] || dept.departamentoId}</h3>
          {dept.cities.map((city) => (
            <div key={city.cityName} className="city-section">
              <h4 className="city-title">Ciudad: {city.cityName}</h4>
              <div className="deparment-container">
                {city.attractions.map((attraction) => (
                  <div key={attraction.id} className="attraction-card">
                    <img
                      src={attraction.images[0]}
                      alt={attraction.name}
                      className="attraction-image"
                    />
                    <div className="attraction-info">
                      <h4 className="attraction-name">{attraction.name}</h4>
                      <p className="attraction-description">
                        {attraction.description}
                      </p>
                      <a
                        href={`https://www.google.com/maps/search/?api=1&query=${attraction.latitude},${attraction.longitude}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="attraction-map-link"
                      >
                        Ver en el mapa
                      </a>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}
