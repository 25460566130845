import React, { useEffect, useState } from "react";
import { getPresidentsForPoliticalParty } from "../../services/dataProcessing";
import TotalEntity from "../TotalEntity";
import "./presidents.css"; // Importa el archivo CSS
import ListPoliticalParties from "./ListPoliticalParties";
import ExecutionTime from "../ExecutionTime";

export default function Presidents() {
  const [presidentsForPoliticalParty, setPresidentsForPoliticalParty] =
    useState([]);
  const [total, setTotal] = useState(0);
  const [time, setTime] = useState(0);

  const calculatorTotal = (politicalParties) => {
    const total = politicalParties.reduce(
      (acc, president) => acc + president.count,
      0
    );
    setTotal(total);
  };

  useEffect(() => {
    getPresidentsForPoliticalParty().then(({ data, time }) => {
      setPresidentsForPoliticalParty(data);
      calculatorTotal(data);
      setTime(time);
    });
  }, []);

  return (
    <>
      <h2 className="title">Presidentes por partido político</h2>
      <br />
      <TotalEntity total={total} />
      <ExecutionTime time={time} />
      <br />
      <ListPoliticalParties
        presidentsForPoliticalParty={presidentsForPoliticalParty}
      />
    </>
  );
}
